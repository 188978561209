<template>
    <div class="wrap">
        <div style="margin-bottom:42px">
            <van-checkbox-group v-model="approveLinkUserList" @change="changeSelectedData">
                <van-cell-group>
                    <van-cell style="align-items: center;" :icon="personIcon" v-for="(item,index) in linkUserList" :key="item.value" :title="item.title" @click.once="toggle(index)" >
                        <template #right-icon>
                            <van-checkbox :name="item.value" ref="checkboxes" />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
            <div class="fixed-bottom">
                <van-row type="flex" justify="center">
                <van-col style="width:100%">
                    <van-button round type="info" class="vanButton" @click="handleSelectUser">确 定</van-button>
                </van-col>
                </van-row>
            </div> 
        </div>
    </div>
</template>
<script>
import personIcon from '@/assets/person.png'
export default {
    data(){
        return{
            approveLinkUserList:[],
            linkUserList:[],
            userSelectedList:[],
            personIcon:personIcon,
        }
    },
    mounted(){
        this.approveLinkUserList = JSON.parse(sessionStorage.getItem('approveLinkUserList'))
        this.userSelectedList = JSON.parse(sessionStorage.getItem('userSelectedList'))
        this.linkUserList = JSON.parse(sessionStorage.getItem('userlist'));
    },
    methods:{
        toggle(index){
            this.$refs.checkboxes[index].toggle();
        },
        changeSelectedData(data){
            this.userSelectedList = [];
            this.linkUserList.forEach(item=>{
                if(data.includes(item.value)){
                    this.userSelectedList.push(item.title);
                }
            })
        },
        handleSelectUser(){
            sessionStorage.setItem('approveLinkUserList',JSON.stringify(this.approveLinkUserList))
            sessionStorage.setItem('userSelectedList',JSON.stringify(this.userSelectedList))
            this.$router.go(-1)
        },
    }
}
</script>
<style lang="scss" scoped>
    .fixed-bottom{
        position:fixed;bottom:0;width:100%;
    }
    .vanButton{
        width:100%;
        height:0.42rem;
        border-radius:0.03rem
    }
    ::v-deep .van-cell__left-icon, .van-cell__right-icon {
        height: 0.38rem;
        font-size: 0.38rem;
    }
</style>